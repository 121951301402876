<template>
  <v-container fluid class="pl-0">
    <v-row v-if="files.length">
      <v-col cols="12" xs="6" sm="4" md="3" lg="2" class="pl-0" v-for="(file, index) in files" :key="index">
        <card-file
            :file="file"
            :uri="uri"
            :habilitar-exclusao="habilitarExclusao && file.canDelete"
            @CardFile_remove="arquivoRemovido">
        </card-file>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!files.length && !alertarSalvar">
      {{ $t('label.tabela_sem_anexo') }}
    </v-row>
    <v-row justify="center" v-if="alertarSalvar">
      {{ $t('label.anexo_necessario_salvar_antes') }}
    </v-row>
  </v-container>
</template>

<script>
import CardFile from './CardFile';

export default {
  name: 'ListFiles',
  components: { CardFile },
  props: {
    uri: '',
    habilitarExclusao: {
      type: Boolean,
      default: false,
    },
    alertarSalvar: false,
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    possuiArquivos(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('ListFiles_ITENS_ALTERADOS', newValue);
      }
    },
  },
  computed: {
    possuiArquivos() {
      return !!(this.files && this.files.length);
    },
  },
  methods: {
    carregarArquivos(callback) {
      this.$http.get(`${this.uri}/list`).then((response) => {
        this.files = response.body;
        this.$emit('ListFiles__arquivos', this.files);
        if (callback) {
          callback();
        }
      });
    },
    refresh(callback) {
      this.files = [];
      setTimeout(() => this.carregarArquivos(callback), 1E2);
    },
    arquivoRemovido() {
      this.$emit('ListFiles__arquivoRemovido');
      this.refresh();
    },
  },
  mounted() {
    this.carregarArquivos();
  },
};
</script>

<style scoped>

</style>
